import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../admin/shared/table';
import LoaderComponent from '../../admin/shared/loader';
import axios from 'axios';

export default function InfrastructureListComponent() {

    const columns = [
        {
            field: 'title',
            lable: 'Title'
        },
        {
            field: 'image',
            lable: 'image'
        },];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    useEffect(() => {
        getInfrastructure();
    }, []);

    useEffect(() => {
        isRecall && getInfrastructure();
    }, [isRecall]);

    const getInfrastructure = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_INFRASTRUCTURE_LIST, {}).then(res => {
            setData(res.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_INFRASTRUCTURE_GET + id).then((response) => {
            setIsRecall(true);
        });
    };

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Infrastructure
                        <NavLink to={PAGE_ROUTE.ADMIN_INFRASTRUCTURE_CREATE} className="btn btn-primary">
                            Add
                        </NavLink>
                    </h4>
                    {
                        isLoading && <LoaderComponent height={80} width={80}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    isEditBtn={false}
                                >
                                </TableComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
