import React, { useEffect, useState } from 'react';

import MainScrollBar from "./MainScrollBar";
import {SmoothScrollbarProps} from "./SmoothScrollbar";
import DroowMenu from "../components/header/menu/DroowMenu";
import LoadingPage from "../hooks/LoadingPage";
import axios from 'axios';
import { API_URL } from '../admin/shared/constant';

function Layout(
    {className, children, tag: Tag = "div", activeScrollbar, ...restProps}) {
        const [isBusy, setBusy] = useState(false);
        const [menuContent, setMenuContent] = useState([
            {name: "Home", to: "/"},
            {
                name: "Product",
                dropdownMenu: []
            },
            {name: "About", to: "/about"},
            {name: "Catalogue", to: "/catalogue"},
            {name: "Installation", to: "/installation"},
            {name: "Contact", to: "/contact"},
        ]);

        useEffect(() => {
            localStorage.removeItem("sliders");
            axios.post(API_URL.SITE_CATEGORY, {}).then(res => {
                if (res.data.data && res.data.data.length) {
                    let menus = menuContent;
                    menus[1].dropdownMenu = res.data.data.map((row) => {
                        return {name: row.name, to: "/products/" + row.slug}
                    });
                    setBusy(true);
                    setMenuContent(menus);
                }
            })
        }, []);

    return (
        <Tag id="main_layout" className={className}>
            { (isBusy && menuContent[1].dropdownMenu.length > 0) ? <DroowMenu menuContent={menuContent}/> : null }
            <LoadingPage/>
            {activeScrollbar ? <MainScrollBar  {...restProps}>{children}</MainScrollBar> : children}
        </Tag>
    );
}

export default Layout;