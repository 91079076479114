import React from 'react';
import { ThreeDots, Watch } from 'react-loader-spinner'

const LoaderComponent = ({ height, width, type, style }) => {

    return (
        <React.Fragment>
            {!type &&
                <div className='row'>
                    <div className='col-md-12' style={{ margin: 'auto', width: '9%' }}>
                        <ThreeDots color="#00BFFF" height={height} width={width} />
                    </div>
                </div>
            }
            {
                type === 'watch' &&
                <div style={style ? style : {
                    position: 'absolute',
                    right: '185px'
                }}>
                    <Watch color="#00BFFF" height={height} width={width} />
                </div>
            }
        </React.Fragment>
    )
}

export default LoaderComponent; 