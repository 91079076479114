import React, { useEffect, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import LoaderComponent from '../../admin/shared/loader';
const SeriesFromComponent = () => {

    let navigate = useNavigate();
    let { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [subCategoryDrp, setSubCategoryDrp] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        sub_category_id: '',
        description: '',
    })
    const [error, setError] = useState([]);

    useEffect(() => {
        id && onEdit();
    }, [id]);


    const getCategoryDrpData = () => {
        axios.post(API_URL.ADMIN_CATEGORY_LIST, {}).then(res => {
            setCategoryDrpData(res.data.data)
        })
    }

    const getSubCategoryDrpData = (value) => {
        axios.post(API_URL.ADMIN_SUB_CATEGORY_BY_CATEGORY, { category_id: value }).then(res => {
            setSubCategoryDrp(res.data.data)
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (isInvalid(formData.name)) {
            toast.error("Name Is Required");
            return false;
        }

        if (isInvalid(formData.category_id)) {
            toast.error("Category Is Required");
            return false;
        }

        // if (isInvalid(formData.sub_category_id)) {
        //     toast.error("Sub Category Is Required");
        //     return false;
        // }

        // if (isInvalid(formData.description)) {
        //     toast.error("Description Is Required");
        //     return false;
        // }

        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_SERIES_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_SERIES_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Series Updated Successfully', { autoClose: 3000 });
                } else {
                    toast.success('Series Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_SERIES_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value == '' || value == null);
    };

    useEffect(() => {
        getCategoryDrpData();
    }, []);


    const onEdit = () => {
        axios.post(API_URL.ADMIN_SERIES_GET + id).then((response) => {
            if (response.data.status === 1) {
                getSubCategoryDrpData(response.data.data.category_id);
                setFormData({
                    name: response.data.data.name,
                    category_id: response.data.data.category_id,
                    description: response.data.data.description,
                    sub_category_id: response.data.data.sub_category_id
                })
            }
        });
    }


    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{id ? 'Edit' : 'Add'} Series
                    {error.length > 0 &&
                        <>
                            {[
                                'danger',
                            ].map((variant) => (
                                <Alert key={variant} variant={variant}>
                                    {error.map((element, key) => {
                                        return (
                                            <div key={key}>{element}</div>
                                        )
                                    })}
                                </Alert>
                            ))}
                        </>
                    }
                </h4>
                <form className="forms-sample" onSubmit={onSubmit}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                {/* <span className='start'>*</span> */}
                                <input type="text"
                                    name='name'
                                    className="form-control"
                                    value={formData.name}
                                    id="name"
                                    onChange={(event) => {
                                        setFormData({ ...formData, name: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="Category">Category</label>
                                {/* <span className='start'>*</span> */}
                                <Form.Select aria-label="Category" value={formData.category_id} onChange={(event) => {
                                    getSubCategoryDrpData(event.target.value);
                                    setFormData({ ...formData, category_id: event.target.value })
                                }}>
                                    value={formData.category_id}
                                    <option value="">Select</option>
                                    {
                                        categoryDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="Sub Category">Sub Category</label>
                                <Form.Select
                                    aria-label="Category"
                                    value={formData.sub_category_id}
                                    disabled={!formData.category_id}
                                    onChange={(event) => {
                                        setFormData({ ...formData, sub_category_id: event.target.value })
                                    }}>
                                    value={formData.sub_category_id}
                                    <option value="">Select</option>
                                    {
                                        subCategoryDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        {/* <div className='col-md-12'>
                            <div className="form-group">
                                <label htmlFor="Description">Description</label>
                                <span className='start' >*</span>
                                <Form.Control
                                    as="textarea"
                                    value={formData.description}
                                    style={{ height: '100px' }}
                                    onChange={(event) => {
                                        setFormData({ ...formData, description: event.target.value })
                                    }}
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className='text-end'>
                        {
                            isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                        }
                        <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                        <NavLink to={PAGE_ROUTE.ADMIN_SERIES_LIST} className="btn btn-secondary">
                            Cancel
                        </NavLink>
                    </div>
                </form>
            </div>
        </div>
    );

}
export default SeriesFromComponent;