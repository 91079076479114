import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../admin/shared/table';
import LoaderComponent from '../../admin/shared/loader';
import { API_URL, PAGE_ROUTE } from '../shared/constant';

export default function TestimonialListComponent() {

    let columns = [{
        field: 'client_name',
        lable: 'Client Name'
    }, {
        field: 'client_address',
        lable: 'Address'
    },
    {
        field: 'image',
        lable: 'Image'
    }
    ];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    useEffect(() => {
        getTestimonial();
    }, []);

    useEffect(() => {
        isRecall && getTestimonial();
    }, [isRecall]);

    const getTestimonial = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_TESTIMONIAL_LIST, {}).then(res => {
            setData(res.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    }

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_TESTIMONIAL_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    }

    const onEdit = (item) => {
        navigate(PAGE_ROUTE.ADMIN_TESTIMONIAL_EDIT + item.id, { replace: true });
    }


    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Testimonial
                        <NavLink to={PAGE_ROUTE.ADMIN_TESTIMONIAL_CREATE} className="btn btn-primary">
                            Add
                        </NavLink>
                    </h4>
                    {
                        isLoading && <LoaderComponent height={100} width={100}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
