import React, { useLayoutEffect } from "react";
import 'swiper/css/pagination';
import 'bootstrap/dist/css/bootstrap-grid.css';
import './assets/sass/style.scss';
import Router from "./router/Router";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import dsnEffect, { fade, moveSection, textAnimation } from "./animation/DsnEffect";

function App() {

    useLayoutEffect(() => {

        gsap.registerPlugin(ScrollTrigger);
        dsnEffect.registerEffect(moveSection, fade, textAnimation)


        gsap.config({
            nullTargetWarn: false
        });
        gsap.defaults({
            ease: "none",
            duration: 1,
            overwrite: "auto",
        });
    }, []);

    return (
        <Router />
    );
}

export default App;
