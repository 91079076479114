import { React, useState } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import { API_URL, PAGE_ROUTE } from '../../admin/shared/constant';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Background from '../assets/image/login-bg.jpg';


const LoginComponent = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState([]);

    const validateForm = () => {
        return email.length > 0 && password.length > 0;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setError([]);
        axios.post(API_URL.LOGIN_URL, { email: email, password: password }).then(res => {
            if (res.data.status === 1) {
                sessionStorage.setItem('access_token', res.data.data.access_token);
                navigate(PAGE_ROUTE.ADMIN_DASHBOARD, { replace: true });
            } else {
                if (res.data && res.data.data) {
                    let errors = [];
                    Object.keys(res.data.data).forEach((key) => {
                        errors.push(res.data.data[key]);
                    });
                    setError(errors);
                }
                toast.error('Invalid Username or Password', { autoClose: 3000 })
            }
        }, error => {
            setError([]);
            toast.error('Something went wrong..', { autoClose: 3000 })
        })
    }

    return (
        <>
            <div style={{ 
                    background: `url(${Background})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '100vh' 
                }}>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <Form className="login-from">
                    <div className="login-text">
                        Login
                    </div>
                    <Form.Group size="lg" controlId="email" className='login-page'>
                        <Form.Label className="mb-0">Email&nbsp;<span className='start'>*</span></Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            placeholder=""
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="password" className='login-page mt-2'>
                        <Form.Label className="mb-0">Password&nbsp;<span className='start'>*</span></Form.Label>
                        <Form.Control
                            type="password"
                            value={password}
                            placeholder=""
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <div className='text-center w-100 mt-3'>
                        <Button onClick={handleSubmit}
                            block="true"
                            size="lg"
                            type="button"
                            className='login-btn'
                            disabled={!validateForm()}>
                            Login
                        </Button>
                    </div>
                </Form>

            </div>
            <ToastContainer />
        </>
    )
}
export default LoginComponent;