import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import LoaderComponent from '../../admin/shared/loader';
const NewsFormComponent = () => {

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState([]);
    let { id } = useParams();
    const [formData, setFormData] = useState({
        title: '',
        image: '',
        description: RichTextEditor.createEmptyValue()
    });

    const onChange = (v) => {
        setFormData({ ...formData, description: v })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (isInvalid(formData.title)) {
            toast.error("Title Is Required");
            return false;
        }
        if (isInvalid(formData.image)) {
            toast.error("Image Is Required");
            return false;
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_NEWS_SAVE;
        let body = JSON.parse(JSON.stringify(formData));
        body.description = formData.description.toString('html');
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_NEWS_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('News Updated Successfully', { autoClose: 3000 });
                } else {
                    toast.success('News Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_NEWS_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value === null);
    };

    const onEdit = () => {
        axios.post(API_URL.ADMIN_NEWS_GET + id).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    title: response.data.data.title,
                    description: RichTextEditor.createValueFromString(response.data.data.description, 'html')
                });
            }
        });
    }
    useEffect(() => {
        id && onEdit();
    },[id])


    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{id ? 'Edit' : 'Add'} News</h4>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <form className="forms-sample" onSubmit={onSubmit}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Title</label>
                                {/* <span className='start'>*</span> */}
                                <input className='form-control'
                                    name='title'
                                    type="text"
                                    value={formData.title}
                                    onChange={(event) => {
                                        setFormData({ ...formData, title: event.target.value })
                                    }}
                                ></input>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Image</label>
                                <input
                                    className='form-control'
                                    accept='image/*'
                                    name='Image'
                                    type="file"
                                    onChange={(e) => {
                                        if (e.target.files && e.target.files[0]) {
                                            var reader = new FileReader();
                                            reader.readAsDataURL(e.target.files[0]);
                                            reader.onload = function () {
                                                setFormData({ ...formData, image: reader.result });
                                            };
                                            reader.onerror = function (error) {
                                                console.log('Error: ', error);
                                            };
                                        }
                                    }} />
                            </div>
                        </div>
                        <div className="form-group {id ? 'col-md-8' : 'col-md-12'}">
                            <div className="form-group" style={{ height: '400px' }}>
                                <div style={{ height: '400px' }}>
                                    <label>Description</label>
                                    <RichTextEditor
                                        value={formData.description}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {id &&
                            <div className='col-md-4'>
                                <label>Preview</label>
                                <div>
                                    <a href={formData.image} target="_blank" rel="noopener noreferrer">
                                        <img src={formData.image} height={100} width={100} alt="news_image" />
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='text-end'>
                        {
                            isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                        }
                        <button type="submit" className="btn btn-primary">Submit</button>
                        <NavLink to={PAGE_ROUTE.ADMIN_NEWS_LIST} className="btn btn-secondary">
                            Cancel
                        </NavLink>
                    </div>
                </form>
            </div >
        </div >
    );

}
export default NewsFormComponent;