import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../admin/shared/table';
import LoaderComponent from '../../admin/shared/loader';
import axios from 'axios';

export default function ProductListComponent() {

    const columns = [
        {
            field: 'image',
            lable: 'Image'
        },
        {
            field: 'name',
            lable: 'Name'
        }, {
            field: 'category_name',
            lable: 'Category'
        },];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    useEffect(() => {
        getProduct();
    }, []);

    useEffect(() => {
        isRecall && getProduct();
    }, [isRecall]);

    const getProduct = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PRODUCT_LIST, {}).then(res => {
            setData(res.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_PRODUCT_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    };

    const onEdit = (item) => {
        navigate(PAGE_ROUTE.ADMIN_PRODUCT_EDIT + item.id, { replace: true });
    };


    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Products
                        <NavLink to={PAGE_ROUTE.ADMIN_PRODUCT_CREATE} className="btn btn-primary">
                            Add
                        </NavLink>
                    </h4>
                    {
                        isLoading && <LoaderComponent height={80} width={80}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
