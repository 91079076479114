import AboutUsComponent from './../admin/about-us/about-us-form';
import CategoryListComponent from './../admin/category/category-list';
import CategoryFormComponent from './../admin/category/category-form';
import DashboardComponent from './../admin/dashboard/dashboard';
import ContactUsComponent from './../admin/contact-us/contact-us-form';
import InquiryListComponent from './../admin/inquiry/inquiry-list';
import ProductListComponent from './../admin/product/product-list';
import ProductFormComponent from './../admin/product/product-form';
import SliderListComponent from './../admin/slider/slider-list';
import SliderFormComponent from './../admin/slider/slider-form';
import SubCategoryListComponent from './../admin/sub-category/sub-category-list';
import SubCategoryFromComponent from './../admin/sub-category/sub-category-form';
import SizeListComponent from './../admin/size/size-list';
import SizeFromComponent from './../admin/size/size-form';
import SeriesListComponent from './../admin/series/series-list';
import SeriesFormComponent from './../admin/series/series-form';
import SpecificationFormComponent from './../admin/specification/specification';
import SharedFromComponent from './../admin/shared/table';
import CatalogueListFromComponent from './../admin/catalogue/catalogue-list';
import CatalogueFormComponent from './../admin/catalogue/cataloge-form';
import NewsListComponent from './../admin/news/news-list';
import NewsFormComponent from './../admin/news/news-form';
import InfrastructureListComponent from './../admin/infrastructure/infrastructure-list';
import InfrastructureFormComponent from './../admin/infrastructure/infrastructure-form';
import SettingComponent from './../admin/setting/setting'
import TestimonialListComponent from './../admin/testimonial/testimonial-list';
import TestimonialFormComponent from './../admin/testimonial/testimonial-form';
import TeamsListComponent from './../admin/teams/teams-list';
import TeamsFormComponent from './../admin/teams/teams-form';

const AdminRoutes = [
    { path: '', component: <DashboardComponent /> },
    { path: 'dashboard', component: <DashboardComponent /> },
    { path: 'about', component: <AboutUsComponent /> },
    { path: 'category', component: <CategoryListComponent /> },
    { path: 'category/create', component: <CategoryFormComponent /> },
    { path: 'category/edit/:id', component: <CategoryFormComponent /> },
    { path: 'contact', component: <ContactUsComponent /> },
    { path: 'inquiry', component: <InquiryListComponent /> },
    { path: 'product', component: <ProductListComponent /> },
    { path: 'product/create', component: <ProductFormComponent /> },
    { path: 'product/edit/:id', component: <ProductFormComponent /> },
    { path: 'slider', component: <SliderListComponent /> },
    { path: 'slider/create', component: <SliderFormComponent /> },
    { path: 'slider/edit/:id', component: <SliderFormComponent /> },
    { path: 'sub-category', component: <SubCategoryListComponent /> },
    { path: 'sub-category/create', component: <SubCategoryFromComponent /> },
    { path: 'sub-category/edit/:id', component: <SubCategoryFromComponent /> },
    { path: 'size', component: <SizeListComponent /> },
    { path: 'size/edit/:id', component: <SizeFromComponent /> },
    { path: 'size/create', component: <SizeFromComponent /> },
    { path: 'series', component: <SeriesListComponent /> },
    { path: 'series/edit/:id', component: <SeriesFormComponent /> },
    { path: 'series/create', component: <SeriesFormComponent /> },
    { path: 'specification', component: <SpecificationFormComponent /> },
    { path: 'shared', component: <SharedFromComponent /> },
    { path: 'catalogue', component: <CatalogueListFromComponent /> },
    { path: 'catalogue/create', component: <CatalogueFormComponent /> },
    { path: 'catalogue/edit/:id', component: <CatalogueFormComponent /> },
    { path: 'news', component: <NewsListComponent /> },
    { path: 'news/create', component: <NewsFormComponent /> },
    { path: 'news/edit/:id', component: <NewsFormComponent /> },
    { path: 'infrastructure', component: <InfrastructureListComponent /> },
    { path: 'infrastructure/create', component: <InfrastructureFormComponent /> },
    { path: 'infrastructure/edit/:id', component: <InfrastructureFormComponent /> },
    { path: 'testimonial', component: <TestimonialListComponent /> },
    { path: 'testimonial/create', component: <TestimonialFormComponent /> },
    { path: 'testimonial/edit/:id', component: <TestimonialFormComponent /> },
    { path: 'teams', component: <TeamsListComponent /> },
    { path: 'teams/create', component: <TeamsFormComponent /> },
    { path: 'teams/edit/:id', component: <TeamsFormComponent /> },
    { path: 'setting', component: <SettingComponent /> }
];

export default AdminRoutes;