import React, {useState, Suspense} from "react";
import { useSelector } from "react-redux";
import Layout from "./Layout";

const ClientLayoutRoute = ({ component: Component }) => {

    const [transPage, setTransPage] = useState("in");
    const scrollbar = useSelector(state => state.scrollbar);

    const onAnimateEnd = () => {
        // if (transPage !== "out") return;
        scrollbar.current?.scrollTo(0, 0, 0);
        window.scrollTo({ left: 0, top: 0 });
        // setTransPage("in");
    }

    return (<Layout className="v-dark background-main" tag="main" activeScrollbar={true}>
        <div id="dsn-content" className={`dsn-transition-page dsn-animate-${transPage}`}
            onAnimationEnd={onAnimateEnd}>
            <Suspense fallback={<div className="background-main h-100-v" />}>
                {Component}
            </Suspense>
        </div>
    </Layout>)
}
export default ClientLayoutRoute;  