// const ADMIN_BASE_URL = 'http://127.0.0.1:8000/api/admin/';
// const SITE_BASE_URL = 'http://127.0.0.1:8000/api/';
const SITE_BASE_URL = 'https://api.hitnish.com/api/';
const ADMIN_BASE_URL = 'https://api.hitnish.com/api/admin/';

export const PAGE_ROUTE = {
    ADMIN_DASHBOARD: '/admin/dashboard',
    ADMIN_CATEGORY_LIST: '/admin/category',
    ADMIN_CATEGORY_CREATE: '/admin/category/create',
    ADMIN_CATEGORY_EDIT: '/admin/category/edit/',

    ADMIN_SUB_CATEGORY_LIST: '/admin/sub-category',
    ADMIN_SUB_CATEGORY_CREATE: '/admin/sub-category/create',
    ADMIN_SUB_CATEGORY_EDIT: '/admin/sub-category/edit/',

    ADMIN_SLIDER_LIST: '/admin/slider',
    ADMIN_SLIDER_CREATE: '/admin/slider/create',
    ADMIN_SLIDER_EDIT: '/admin/slider/edit/',

    ADMIN_TEAMS_LIST: '/admin/teams',
    ADMIN_TEAMS_CREATE: '/admin/teams/create',
    ADMIN_TEAMS_EDIT: '/admin/teams/edit/',

    ADMIN_TESTIMONIAL_LIST: '/admin/testimonial',
    ADMIN_TESTIMONIAL_CREATE: '/admin/testimonial/create',
    ADMIN_TESTIMONIAL_EDIT: '/admin/testimonial/edit/',

    ADMIN_SIZE_LIST: '/admin/size',
    ADMIN_SIZE_CREATE: '/admin/size/create',
    ADMIN_SIZE_EDIT: '/admin/size/edit/',

    ADMIN_SERIES_LIST: '/admin/series',
    ADMIN_SERIES_CREATE: '/admin/series/create',
    ADMIN_SERIES_EDIT: '/admin/series/edit/',

    ADMIN_INQUIRY_LIST: '/admin/inquiry',
    ADMIN_INQUIRY_CREATE: '/admin/inquiry/create',
    ADMIN_INQUIRY_EDIT: '/admin/inquiry/edit/',

    ADMIN_PRODUCT_LIST: '/admin/product',
    ADMIN_PRODUCT_CREATE: '/admin/product/create',
    ADMIN_PRODUCT_EDIT: '/admin/product/edit/',

    ADMIN_CATALOGUE_LIST: '/admin/catalogue',
    ADMIN_CATALOGUE_CREATE: '/admin/catalogue/create',
    ADMIN_CATALOGUE_EDIT: '/admin/catalogue/edit/',

    ADMIN_NEWS_LIST: '/admin/news',
    ADMIN_NEWS_CREATE: '/admin/news/create',
    ADMIN_NEWS_EDIT: '/admin/news/edit/',

    ADMIN_INFRASTRUCTURE_LIST: '/admin/infrastructure',
    ADMIN_INFRASTRUCTURE_CREATE: '/admin/infrastructure/create',
}

export const API_URL = {
    
    SITE_CONTACT_ADD: `${SITE_BASE_URL}save-inquiry`,
    SITE_CONTACT_INFO: `${SITE_BASE_URL}contact-info`,
    SITE_PRODUCT: `${SITE_BASE_URL}products`,
    SITE_PRODUCT_BY_CATEGORY_SLUG: `${SITE_BASE_URL}products-by-category-slug`,
    SITE_SUBCATEGORY: `${SITE_BASE_URL}sub-category-by-category`,
    SITE_PRODUCT_BY_SUB_CATEGORY_SLUG: `${SITE_BASE_URL}products-by-sub-category-slug`,
    SITE_PRODUCT_BY_SIZE_SLUG: `${SITE_BASE_URL}products-by-size-slug`,
    SITE_PRODUCT_BY_SERIES_SLUG: `${SITE_BASE_URL}products-by-series-slug`,
    SITE_PRODUCT_BY_ID: `${SITE_BASE_URL}product-by-id`,
    SITE_PRODUCT_BY_SLUG: `${SITE_BASE_URL}product-by-slug`,
    SITE_CATEGORY: `${SITE_BASE_URL}all-category`,
    SITE_SHOW_CATEGORY: `${SITE_BASE_URL}show-category`,
    SITE_SHOW_SUB_CATEGORY: `${SITE_BASE_URL}show-sub-category`,
    SITE_PACKINGS: `${SITE_BASE_URL}packing-details`,
    SITE_NEWS: `${SITE_BASE_URL}news`,
    SITE_NEWS_BY_SLUG: `${SITE_BASE_URL}news-by-slug`,
    SITE_PDFS: `${SITE_BASE_URL}pdfs-list`,
    SITE_INFRASTRUCTURE_CATEGORY: `${SITE_BASE_URL}infrastructure-category`,
    SITE_SLIDERS: `${SITE_BASE_URL}sliders`,

    ADMIN_CATEGORY_LIST: `${ADMIN_BASE_URL}category`,
    ADMIN_CATEGORY_GET: `${ADMIN_BASE_URL}category/show?id=`,
    ADMIN_CATEGORY_SAVE: `${ADMIN_BASE_URL}category/store`,
    ADMIN_CATEGORY_UPDATE: `${ADMIN_BASE_URL}category/update`,
    ADMIN_CATEGORY_DELETE: `${ADMIN_BASE_URL}category/delete?id=`,


    ADMIN_SLIDER_LIST: `${ADMIN_BASE_URL}slider`,
    ADMIN_SLIDER_GET: `${ADMIN_BASE_URL}slider/show?id=`,
    ADMIN_SLIDER_SAVE: `${ADMIN_BASE_URL}slider/store`,
    ADMIN_SLIDER_UPDATE: `${ADMIN_BASE_URL}slider/update`,
    ADMIN_SLIDER_DELETE: `${ADMIN_BASE_URL}slider/delete?id=`,

    ADMIN_TEAMS_LIST: `${ADMIN_BASE_URL}teams`,
    ADMIN_TEAMS_GET: `${ADMIN_BASE_URL}teams/show?id=`,
    ADMIN_TEAMS_SAVE: `${ADMIN_BASE_URL}teams/store`,
    ADMIN_TEAMS_UPDATE: `${ADMIN_BASE_URL}teams/update`,
    ADMIN_TEAMS_DELETE: `${ADMIN_BASE_URL}teams/delete?id=`,

    ADMIN_TESTIMONIAL_LIST: `${ADMIN_BASE_URL}testimonial`,
    ADMIN_TESTIMONIAL_GET: `${ADMIN_BASE_URL}testimonial/show?id=`,
    ADMIN_TESTIMONIAL_SAVE: `${ADMIN_BASE_URL}testimonial/store`,
    ADMIN_TESTIMONIAL_UPDATE: `${ADMIN_BASE_URL}testimonial/update`,
    ADMIN_TESTIMONIAL_DELETE: `${ADMIN_BASE_URL}testimonial/delete?id=`,

    ADMIN_SIZE_LIST: `${ADMIN_BASE_URL}size`,
    ADMIN_SIZE_GET: `${ADMIN_BASE_URL}size/show?id=`,
    ADMIN_SIZE_SAVE: `${ADMIN_BASE_URL}size/store`,
    ADMIN_SIZE_UPDATE: `${ADMIN_BASE_URL}size/update`,
    ADMIN_SIZE_DELETE: `${ADMIN_BASE_URL}size/delete?id=`,

    ADMIN_SERIES_LIST: `${ADMIN_BASE_URL}series`,
    ADMIN_SERIES_GET: `${ADMIN_BASE_URL}series/show?id=`,
    ADMIN_SERIES_SAVE: `${ADMIN_BASE_URL}series/store`,
    ADMIN_SERIES_UPDATE: `${ADMIN_BASE_URL}series/update`,
    ADMIN_SERIES_DELETE: `${ADMIN_BASE_URL}series/delete?id=`,

    ADMIN_NEWS_LIST: `${ADMIN_BASE_URL}news`,
    ADMIN_NEWS_GET: `${ADMIN_BASE_URL}news/show?id=`,
    ADMIN_NEWS_SAVE: `${ADMIN_BASE_URL}news/store`,
    ADMIN_NEWS_UPDATE: `${ADMIN_BASE_URL}news/update`,
    ADMIN_NEWS_DELETE: `${ADMIN_BASE_URL}news/delete?id=`,

    ADMIN_SPEC_LIST: `${ADMIN_BASE_URL}specs`,
    ADMIN_SPEC_GET: `${ADMIN_BASE_URL}specs/show?id=`,
    ADMIN_SPEC_SAVE: `${ADMIN_BASE_URL}specs/store`,
    ADMIN_SPEC_UPDATE: `${ADMIN_BASE_URL}specs/update`,
    ADMIN_SPEC_DELETE: `${ADMIN_BASE_URL}specs/delete?id=`,
    ADMIN_SUB_CATEGORY_LIST: `${ADMIN_BASE_URL}sub-category`,
    ADMIN_SUB_CATEGORY_GET: `${ADMIN_BASE_URL}sub-category/show?id=`,
    ADMIN_SUB_CATEGORY_SAVE: `${ADMIN_BASE_URL}sub-category/store`,
    ADMIN_SUB_CATEGORY_UPDATE: `${ADMIN_BASE_URL}sub-category/update`,
    ADMIN_SUB_CATEGORY_DELETE: `${ADMIN_BASE_URL}sub-category/delete?id=`,
    ADMIN_SUB_CATEGORY_BY_CATEGORY: `${ADMIN_BASE_URL}category/sub-category`,

    ADMIN_PRODUCT_LIST: `${ADMIN_BASE_URL}product`,
    ADMIN_PRODUCT_GET: `${ADMIN_BASE_URL}product/show?id=`,
    ADMIN_PRODUCT_SAVE: `${ADMIN_BASE_URL}product/store`,
    ADMIN_PRODUCT_UPDATE: `${ADMIN_BASE_URL}product/update`,
    ADMIN_PRODUCT_DELETE: `${ADMIN_BASE_URL}product/delete?id=`,

    ADMIN_PDF_LIST: `${ADMIN_BASE_URL}pdf`,
    ADMIN_PDF_GET: `${ADMIN_BASE_URL}pdf/show?id=`,
    ADMIN_PDF_SAVE: `${ADMIN_BASE_URL}pdf/store`,
    ADMIN_PDF_UPDATE: `${ADMIN_BASE_URL}pdf/update`,
    ADMIN_PDF_DELETE: `${ADMIN_BASE_URL}pdf/delete?id=`,

    ADMIN_INFRASTRUCTURE_LIST: `${ADMIN_BASE_URL}infrastructure`,
    ADMIN_INFRASTRUCTURE_SAVE: `${ADMIN_BASE_URL}infrastructure/store`,
    ADMIN_INFRASTRUCTURE_GET: `${ADMIN_BASE_URL}infrastructure/delete?id=`,

    ADMIN_SETTING_INQUIRIES: `${ADMIN_BASE_URL}setting/inquiries`,
    ADMIN_SETTING_SAVE: `${ADMIN_BASE_URL}setting/store`,
    ADMIN_SETTING_GET: `${ADMIN_BASE_URL}setting/show`,
    ADMIN_SETTING_PASSWORD: `${ADMIN_BASE_URL}setting/password`,
    ADMIN_ABOUT_US_SAVE: `${ADMIN_BASE_URL}setting/about`,
    LOGIN_URL: `${ADMIN_BASE_URL}login`
}

