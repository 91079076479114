import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Outlet } from "react-router-dom";
import useEffectLocation from "../hooks/useEffectLocation";
import LoginComponent from './../admin/login';
import ClientLayoutRoute from '../layout/ClientLayout';
import AdminLayoutRoute from '../layout/AdminLayout';
import AdminRoutes from '../router/AdminRoute';
import './style.scss';

// const Scrollbar = React.lazy(() => import("smooth-scrollbar"));

const MainDemo = React.lazy(() => import("../views/home/MainDemo"));
const Demo2 = React.lazy(() => import("../views/home/Demo2"));
const Demo3 = React.lazy(() => import("../views/home/Demo3"));

const SliderOne = React.lazy(() => import("../views/slider/SliderOne"));
const SliderTow = React.lazy(() => import("../views/slider/SliderTow"));
const SliderThree = React.lazy(() => import("../views/slider/SliderThree"));

const Work = React.lazy(() => import("../views/Work"));
const WorkTow = React.lazy(() => import("../views/WorkTow"));
const ProjectDetails = React.lazy(() => import("../views/portfolio/ProjectDetails"));

const About = React.lazy(() => import("../views/About"));
const Installation = React.lazy(() => import("../views/Installation"));
const Contact = React.lazy(() => import("../views/Contact"));
const BlogDetails = React.lazy(() => import("../views/blog/BlogDetails"));



const Router = () => {

    const [transPage, setTransPage] = useState("in");
    const scrollbar = useSelector(state => state.scrollbar);

    const location = useEffectLocation((l) => {
        setTransPage("out");
    })

    const onAnimateEnd = () => {
        if (transPage !== "out") return;
        scrollbar.current?.scrollTo(0, 0, 0);
        window.scrollTo({ left: 0, top: 0 });
        setTransPage("in");
    }


    return (
        <div id="dsn-content" onAnimationEnd={onAnimateEnd}>
        <Routes location={location}>
            <Route path="/" element={<Outlet />} >
                <Route path="/" element={<ClientLayoutRoute component={<MainDemo />} />} />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/demo-2" element={<ClientLayoutRoute component={<Demo2 />} />} />
                <Route path="/demo-3" element={<ClientLayoutRoute component={<Demo3 />} />} />
                <Route path="/slider" element={<ClientLayoutRoute component={<SliderOne />} />} />
                <Route path="/slider-2" element={<ClientLayoutRoute component={<SliderTow />} />} />
                <Route path="/slider-3" element={<ClientLayoutRoute component={<SliderThree />} />} />
                <Route exact path="/products/:slug" element={<ClientLayoutRoute component={<Work />} />} />
                <Route exact path="/products/:slug/:pslug" element={<ClientLayoutRoute component={<WorkTow />} />} />
                <Route exact path="/portfolio-2" element={<ClientLayoutRoute component={<WorkTow />} />} />
                <Route exact path="/portfolio/:slug" element={<ClientLayoutRoute component={<ProjectDetails />} />} />
                <Route exact path="/about" element={<ClientLayoutRoute component={<About />} />} />
                <Route exact path="/contact" element={<ClientLayoutRoute component={<Contact />} />} />
                <Route exact path="/catalogue" element={<ClientLayoutRoute component={<BlogDetails />} />} />
                <Route exact path="/installation" element={<ClientLayoutRoute component={<Installation />} />} />
            </Route>
            <Route path="admin" element={<Outlet />} >
                {
                    AdminRoutes.map((element) => {
                        return (
                            <Route key={element} path={element.path} element={<AdminLayoutRoute component={element.component} />} />
                        );
                    })
                }
            </Route>
            <Route exact path="*" element={<h1>Not Found</h1>} />
        </Routes>
        </div>


    );
}

export default React.memo(Router);