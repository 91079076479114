import { React, useState, useEffect, } from 'react';
import { API_URL } from '../../admin/shared/constant';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, Alert } from 'react-bootstrap';

const SettingComponent = () => {

    const [old_password, oldPassword] = useState("");
    const [new_password, newPassword] = useState("");
    const [confirm_password, confirmPassword] = useState("");
    const [error, setError] = useState([]);
    const [formData, setFormData] = useState({
        address_one: '',
        address_two: '',
        mobile_number: '',
        contact_email: '',
        extra_mobile_number: '',
        instagram: '',
        facebook: '',
        twitter:'',
        linkedin:'',
    })

    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalid(formData.address_one)) {
            toast.error("Address Is Required");
            return false;
        }
        setError([]);
        let url = API_URL.ADMIN_SETTING_SAVE;
        axios.post(url, formData).then((response) => {
            if (response.data.status === 1) {
                toast.success('Setting Updated Successfully', { autoClose: 3000 });
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const onUpdatePassword = (e) => {
        e.preventDefault();
        if (isInvalid(old_password)) {
            toast.error("Old Password Is Required");
            return false;
        }
        if (isInvalid(new_password)) {
            toast.error("New Password Is Required");
            return false;
        }
        if (new_password !== confirm_password) {
            toast.error("Password does not match");
            return false;
        }
        setError([]);
        axios.post(API_URL.ADMIN_SETTING_PASSWORD, { old_password: old_password, password: new_password, password_confirmation: confirm_password }).then(response => {
            if (response.data.status === 1) {
                toast.success('Password Updated Successfully', { autoClose: 3000 });
            } else {
                if (response.data.data) {
                    let errors = [];
                    response.data.data.forEach((el) => {
                        errors.push(el);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, error => {
            toast.error('Something went wrong..', { autoClose: 3000 })
        })
    }

    const getSetting = () => {
        axios.post(API_URL.ADMIN_SETTING_GET).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    address_one: response.data.data.address_one,
                    address_two: response.data.data.address_two,
                    mobile_number: response.data.data.mobile_number,
                    contact_email: response.data.data.contact_email,
                    extra_mobile_number: response.data.data.extra_mobile_number,
                    instagram: response.data.data.instagram,
                    facebook: response.data.data.facebook,
                    twitter: response.data.data.twitter,
                    linkedin: response.data.data.linkedin,
                })
            }
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value === null);
    };


    useEffect(() => {
        getSetting();
    }, []);


    return (
        <div className="row">
            <div className="col-md-6">

                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Contact Detail</h4>
                        <form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Address Line 1</label>
                                        <input className='form-control'
                                            name='title'
                                            type="text"
                                            value={formData.address_one ?? ''}
                                            onChange={(event) => {
                                                setFormData({ ...formData, address_one: event.target.value })
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Address Line 2</label>
                                        <input className='form-control'
                                            name='title'
                                            type="text"
                                            value={formData.address_two ?? ''}
                                            onChange={(event) => {
                                                setFormData({ ...formData, address_two: event.target.value })
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <input className='form-control'
                                            name='title'
                                            type="text"
                                            value={formData.mobile_number ?? ''}
                                            onChange={(event) => {
                                                setFormData({ ...formData, mobile_number: event.target.value })
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Whatsapp Number</label>
                                        <input className='form-control'
                                            name='title'
                                            type="text"
                                            value={formData.extra_mobile_number ?? ''}
                                            onChange={(event) => {
                                                setFormData({ ...formData, extra_mobile_number: event.target.value })
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input className='form-control'
                                            name='title'
                                            type="text"
                                            value={formData.contact_email ?? ''}
                                            onChange={(event) => {
                                                setFormData({ ...formData, contact_email: event.target.value })
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Instagram</label>
                                        <input className='form-control'
                                            name='title'
                                            type="text"
                                            value={formData.instagram ?? ''}
                                            onChange={(event) => {
                                                setFormData({ ...formData, instagram: event.target.value })
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Facebook</label>
                                        <input className='form-control'
                                            name='title'
                                            type="text"
                                            value={formData.facebook ?? ''}
                                            onChange={(event) => {
                                                setFormData({ ...formData, facebook: event.target.value })
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Twitter</label>
                                        <input className='form-control'
                                            name='title'
                                            type="text"
                                            value={formData.twitter ?? ''}
                                            onChange={(event) => {
                                                setFormData({ ...formData, twitter: event.target.value })
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Linkedin</label>
                                        <input className='form-control'
                                            name='title'
                                            type="text"
                                            value={formData.linkedin ?? ''}
                                            onChange={(event) => {
                                                setFormData({ ...formData, linkedin: event.target.value })
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className='col-md-12 text-right'>
                                    <div className='text-end'>
                                        <button type="submit" className="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={onUpdatePassword}>
                            <h4 className="card-title">Change Password</h4>
                            {error.length > 0 &&
                                <>
                                    {[
                                        'danger',
                                    ].map((variant) => (
                                        <Alert key={variant} variant={variant}>
                                            {error.map((element, key) => {
                                                return (
                                                    <div key={key}>{element}</div>
                                                )
                                            })}
                                        </Alert>
                                    ))}
                                </>
                            }
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Old Password</label>
                                        <Form.Control
                                            type="password"
                                            value={old_password}
                                            placeholder="Old Password"
                                            onChange={(e) => oldPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <Form.Control
                                            type="password"
                                            value={new_password}
                                            placeholder="New Password"
                                            onChange={(e) => newPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <Form.Control
                                            type="password"
                                            value={confirm_password}
                                            placeholder="Confirm Password"
                                            onChange={(e) => confirmPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12 text-right'>
                                    <div className='text-end'>
                                        <button type="submit" className="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default SettingComponent;